"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepPropVal = void 0;
const mergeDeep_1 = require("./mergeDeep");
// ---------- export Function
const deepPropVal = ({ obj, path, val, keepOld }) => {
    // ---------- set root value
    const condPath = !path || path === '/' || path === '';
    if (condPath)
        return (0, mergeDeep_1.mergeDeep)(obj, val);
    // ---------- set path value
    const newObj = Object.assign({}, obj);
    path.split('.').reduce((prev, curr, i, arr) => {
        const condNext = arr[i + 1];
        const condSelItem = () => prev[curr] || (prev[curr] = {});
        const newVal = () => {
            if (keepOld) {
                const oldVal = prev[curr];
                const keepOldVal = (0, mergeDeep_1.mergeDeep)(oldVal, val);
                prev[curr] = keepOldVal;
            }
            else {
                prev[curr] = val;
            }
        };
        const condVal = condNext ? condSelItem() : newVal();
        return condVal;
    }, newObj);
    return newObj;
};
exports.deepPropVal = deepPropVal;
