"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCallerName = void 0;
const getSomeNames_1 = require("./getSomeNames");
// ---------- other Stuff
function getCallerName(callee) {
    const orig = Error.prepareStackTrace;
    Error.prepareStackTrace = getSomeNames_1.getSomeNames;
    const err = new Error();
    Error.captureStackTrace(err, callee);
    // @ts-ignore
    const funcName = err.stack;
    Error.prepareStackTrace = orig;
    return funcName;
}
exports.getCallerName = getCallerName;
