"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logger = void 0;
const groupName_1 = require("./groupName");
const traceGroup_1 = require("./traceGroup");
// ---------- export Function
const logger = (action, newData, devLog) => {
    // ---------- set Properties
    const { value } = action;
    const path = value === null || value === void 0 ? void 0 : value.path;
    const val = value === null || value === void 0 ? void 0 : value.val;
    const args = (0, groupName_1.groupName)(devLog, path);
    // ---------- set Logs
    console.groupCollapsed(...args);
    console.log(`-----------------`);
    console.log('Changing Value...', { val });
    (0, traceGroup_1.traceGroup)();
    console.log('>>> CENTRAL-DATA', newData);
    console.log(`-----------------`);
    console.groupEnd();
};
exports.logger = logger;
