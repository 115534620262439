"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setData = void 0;
// ---------- import Internals
const initCentralData_1 = require("../../initCentralData");
const setChange_1 = require("./setChange");
// ---------- set Default Function
const setData = (dataToSet) => __awaiter(void 0, void 0, void 0, function* () {
    // ----------- set Central Data Last State
    const ctData = initCentralData_1.initCentralData === null || initCentralData_1.initCentralData === void 0 ? void 0 : initCentralData_1.initCentralData.getState();
    // ----------- set Change Array
    const setArrChanges = (arr) => arr.map(setChange_1.setChange);
    // ----------- set Property as an Array
    const condArray = Array.isArray(dataToSet);
    if (condArray)
        setArrChanges(dataToSet);
    // ----------- set Property as an Object
    const checkObj = typeof dataToSet === 'object';
    const condObj = checkObj && !condArray;
    if (condObj)
        (0, setChange_1.setChange)(dataToSet);
    // ----------- set Property as a Function
    const condFunction = typeof dataToSet === 'function';
    if (condFunction) {
        const call = dataToSet(ctData);
        const condArrayFn = Array.isArray(call);
        if (condArrayFn)
            setArrChanges(call);
        const checkObjFn = typeof call === 'object';
        const condObjFn = checkObjFn && !condArrayFn;
        if (condObjFn)
            (0, setChange_1.setChange)(call);
    }
});
exports.setData = setData;
