"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setConnect = void 0;
const ConnectWithData_1 = require("./ConnectWithData");
const setData_1 = require("./functions/setData");
const useData_1 = require("./functions/useData");
const getCtData_1 = require("./functions/getCtData");
// ---------- export Main Function with initial Data
function setConnect(initData = {}) {
    // ---------- set Connect
    const Connect = (0, ConnectWithData_1.ConnectWithData)(initData);
    // ---------- set Return
    const conected = { Connect, setData: setData_1.setData, useData: useData_1.useData, getCtData: getCtData_1.getCtData };
    return conected;
}
exports.setConnect = setConnect;
