"use strict";
exports.__esModule = true;
exports.useChangeTitle = void 0;
// ----------- set Default
var useChangeTitle = function (CurrRoute) {
    // ----------- set Data
    var currScTitle = CurrRoute.screenTitle;
    // ----------- set Title
    document.title = currScTitle !== null && currScTitle !== void 0 ? currScTitle : 'Project Morfos';
};
exports.useChangeTitle = useChangeTitle;
