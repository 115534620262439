"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setChange = void 0;
// ---------- import Internals
const initCentralData_1 = require("../../initCentralData");
const deepPropVal_1 = require("./deepPropVal");
// ----------- set Main Change Function
const setChange = (eachChange) => {
    // ----------- set Central Data Last State
    const ctData = initCentralData_1.initCentralData === null || initCentralData_1.initCentralData === void 0 ? void 0 : initCentralData_1.initCentralData.getState();
    // ----------- set Each Change
    const { path, val, keepOld } = eachChange;
    const newData = (0, deepPropVal_1.deepPropVal)({ obj: ctData, path, val, keepOld });
    // ----------- call Change Data
    const resultObj = { type: 'CHANGE', value: eachChange, newData };
    initCentralData_1.initCentralData === null || initCentralData_1.initCentralData === void 0 ? void 0 : initCentralData_1.initCentralData.dispatch(resultObj);
};
exports.setChange = setChange;
