"use strict";
exports.__esModule = true;
exports.useUrlManualChange = void 0;
// ----------- import Packs
var react_1 = require("react");
var locationPath_1 = require("./useUrlHistory/locationPath");
// ----------- set Default
var useUrlManualChange = function (info) {
    // ----------- set Effects
    var fxInitRoute = function () {
        // ----------- set Props
        var CurrRoute = info.CurrRoute, setRouteData = info.setRouteData;
        // ----------- set Conditional
        var currPath = CurrRoute.path;
        var condPathName = (0, locationPath_1.locationPath)();
        var condBlank = condPathName === '' ? currPath : condPathName;
        var condManual = condBlank !== currPath;
        // ----------- set New Path
        if (condManual)
            setRouteData(condPathName);
    };
    // ----------- set Hooks
    (0, react_1.useEffect)(fxInitRoute, []);
};
exports.useUrlManualChange = useUrlManualChange;
