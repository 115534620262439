"use strict";
exports.__esModule = true;
exports.listeners = exports.useUrlHistory = void 0;
// @ts-check
// ----------- import Packs
var react_1 = require("react");
var fxHistoryInOut_1 = require("./fxHistoryInOut");
var fxListenersInOut_1 = require("./fxListenersInOut");
var fxPush_1 = require("./fxPush");
var setChange_1 = require("./setChange");
// ----------- export Function
var useUrlHistory = function (info) {
    // ----------- set Props
    var CurrRoute = info.CurrRoute, setRouteData = info.setRouteData;
    // ----------- set Data
    var routePath = CurrRoute.path;
    var handleChange = (0, setChange_1.setChange)(setRouteData);
    // ----------- set Hooks
    (0, react_1.useEffect)((0, fxPush_1.fxPush)(routePath), [routePath]);
    (0, react_1.useEffect)((0, fxListenersInOut_1.fxListenersInOut)(handleChange), []);
    (0, react_1.useEffect)((0, fxHistoryInOut_1.fxHistoryInOut)(handleChange), []);
};
exports.useUrlHistory = useUrlHistory;
/****************************
 * Link de onde foi retirado a ideia do código abaixo:
 * https://gist.github.com/lenkan/357b006dd31a8c78f659430467369ea7
 */
exports.listeners = [];
