"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
exports.__esModule = true;
exports.goTo = exports.Router = void 0;
var src_1 = require("./src");
__exportStar(require("./src/types"), exports);
var routerTools = (0, src_1.setRouter)();
/**
 * Main Component to route all components passed
 *
 * 'Example to Copy':
 *
 * XXX
 *
 * @param setHome - Get Central Data to use
 * @param routes - Get Central Data to use
 * @param getData - Get Central Data to use
 */
exports.Router = routerTools.Router;
// type TpropsRouter = { setHome: string; routes: Troutes; };
/**
 * Some Comment about "goTo Function"
 *
 * 'Example to Copy':
 *
 * const goToLogin = () => goTo('login');
 *
 * @param path - Sets the router Component to render
 *
 */
exports.goTo = routerTools.goTo;
