"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupName = void 0;
function groupName(devLog, path) {
    const formatTxt = '%c CT Change %c %d %c %s';
    const stl1 = 'color: white; background-color: #050; border-radius: 4px; padding: 2px';
    const stl2 = 'color: #9980FF; font-weight: lighter;';
    const stl3 = 'color: #36D4BB; font-style: italic; font-weight: lighter;';
    const args = [formatTxt, stl1, stl2, devLog, stl3, path];
    return args;
}
exports.groupName = groupName;
