"use strict";
exports.__esModule = true;
exports.condWeb = void 0;
// ----------- look if it is Web PlatForm
function condWeb() {
    var checkWeb = window === null || window === void 0 ? void 0 : window.origin;
    var checkIframe = checkWeb !== 'null'; // e.g. Figma plugin
    var condWeb = checkWeb && checkIframe;
    return condWeb;
}
exports.condWeb = condWeb;
