"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initCentralData = void 0;
// ---------- import Packs
const redux_1 = require("redux");
// ---------- import Internals
const logger_1 = require("./logger/");
// ----------- set Main Function
const changes = (ctData, act) => {
    // ----------- set New Data
    const checkCtData = typeof ctData === 'object';
    const condCtData = checkCtData ? ctData : {};
    const newData = Object.assign(Object.assign({}, condCtData), act.newData);
    // ----------- set Logger Info if not 'production'
    if (process.env.NODE_ENV === 'development') {
        const newDevLog = newData.dev ? newData.dev.devLog + 1 : 1;
        newData.dev = Object.assign(Object.assign({}, newData.dev), { devLog: newDevLog });
        (0, logger_1.logger)(act, newData, newDevLog);
    }
    // ----------- set Return
    return Object.assign({}, newData);
};
// ----------- exort Init Central Data
exports.initCentralData = (0, redux_1.createStore)(changes);
