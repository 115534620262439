"use strict";
exports.__esModule = true;
exports.WebRoute = void 0;
var useUrlHistory_1 = require("./useUrlHistory");
var useChangeTitle_1 = require("./useChangeTitle");
var useUrlManualChange_1 = require("./useUrlManualChange");
// ---------- set Component
var WebRoute = function (info) {
    // ----------- set Props
    var CurrRoute = info.CurrRoute;
    // ----------- set Change Title
    (0, useChangeTitle_1.useChangeTitle)(CurrRoute);
    // ----------- set Change Url
    (0, useUrlManualChange_1.useUrlManualChange)(info);
    (0, useUrlHistory_1.useUrlHistory)(info);
    // ----------- set Return
    return CurrRoute;
};
exports.WebRoute = WebRoute;
