"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCtData = void 0;
// ----------- import Packs
const initCentralData_1 = require("../initCentralData");
// ----------- set Default Function
const getCtData = (path) => {
    // ----------- set Central Data Last State
    const ctData = initCentralData_1.initCentralData === null || initCentralData_1.initCentralData === void 0 ? void 0 : initCentralData_1.initCentralData.getState();
    const selectedValue = path(ctData);
    // ----------- set Return
    return selectedValue;
};
exports.getCtData = getCtData;
