"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSomeNames = void 0;
const getSomeNames = (err, stack) => {
    const newArr = [];
    for (const item of stack) {
        const idx = stack.indexOf(item);
        const element = item.getFunctionName();
        const condEl = element === null || element === void 0 ? void 0 : element.match(/invoke/i);
        const condBreak = condEl || idx > 20;
        if (condBreak)
            break;
        const condNext = !element || idx <= 12;
        if (condNext)
            continue;
        newArr.push(element);
    }
    return newArr.filter(item => item && item);
};
exports.getSomeNames = getSomeNames;
