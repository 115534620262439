"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.setRoutes = void 0;
// ---------- export Function
var setRoutes = function (routes) {
    // ----------- iterate Routes
    var routeInfo = {};
    for (var scKey in routes) {
        var moduleInfo = routes[scKey];
        var routeKey = moduleInfo.path;
        routeInfo[routeKey] = __assign({ scKey: scKey }, moduleInfo);
    }
    // ----------- set Return
    return routeInfo;
};
exports.setRoutes = setRoutes;
