"use strict";
exports.__esModule = true;
exports.fxPush = void 0;
var locationPath_1 = require("./locationPath");
var push_1 = require("./push");
// ----------- Push routePath to the browser address if it is not the same as the location path
var fxPush = function (routePath) { return function () {
    var condChangeAddress = routePath !== (0, locationPath_1.locationPath)();
    if (condChangeAddress)
        (0, push_1.push)('/' + routePath);
}; };
exports.fxPush = fxPush;
