"use strict";
exports.__esModule = true;
exports.fxHistoryInOut = void 0;
var fxHistoryInOut = function (setChange) { return function () {
    var callRouter = function () { return setChange(); };
    window.addEventListener('popstate', callRouter);
    return function () {
        window.removeEventListener('popstate', callRouter);
    };
}; };
exports.fxHistoryInOut = fxHistoryInOut;
