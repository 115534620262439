"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useData = void 0;
// ----------- import Packs
const react_redux_1 = require("react-redux");
// ----------- set Default Function
const useData = path => {
    // ----------- set Selector
    const checkType = typeof path === 'string';
    const condType = checkType ? ctDataSel(path) : path;
    const dataSelected = (0, react_redux_1.useSelector)(condType);
    // ----------- set Return
    return dataSelected;
};
exports.useData = useData;
// ----------- set String to Data
const ctDataSel = path => ctData => {
    const pathArr = path.split('.');
    const reduceCb = (p, c) => p && p[c];
    const ctDataSel = pathArr.reduce(reduceCb, ctData);
    return ctDataSel;
};
