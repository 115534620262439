"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.traceGroup = void 0;
const getCallerName_1 = require("./getCallerName");
function traceGroup() {
    const arritems = (0, getCallerName_1.getCallerName)();
    const msg = '%c Look after: "./node_modules/@morfos/central-data"';
    const colors = 'color: white; background-color: #500; ';
    const stl = colors + 'border-radius: 4px; padding: 2px';
    console.groupCollapsed('??? WHERE ???');
    console.log(msg, stl);
    console.log('%c Maybe between...: ', stl, ...arritems);
    console.log({ arritems });
    console.trace('Called From:');
    console.groupEnd();
}
exports.traceGroup = traceGroup;
