"use strict";
exports.__esModule = true;
exports.fxListenersInOut = void 0;
var index_1 = require("./index");
var fxListenersInOut = function (setChange) { return function () {
    index_1.listeners.push(setChange);
    return function () {
        index_1.listeners.splice(index_1.listeners.indexOf(setChange), 1);
    };
}; };
exports.fxListenersInOut = fxListenersInOut;
