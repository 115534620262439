"use strict";
exports.__esModule = true;
exports.push = void 0;
var notify_1 = require("./notify");
// ----------- set Change Urls
var push = function (url) {
    window.history.pushState(null, '', url);
    (0, notify_1.notify)();
};
exports.push = push;
