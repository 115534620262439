"use strict";
exports.__esModule = true;
exports.setChange = void 0;
var locationPath_1 = require("./locationPath");
var setChange = function (setSelected) { return function () {
    var newPath = (0, locationPath_1.locationPath)();
    var checkPath = newPath === '';
    if (checkPath)
        return;
    setSelected(newPath);
}; };
exports.setChange = setChange;
