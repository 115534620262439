"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeDeep = void 0;
// ---------- export Function
const mergeDeep = (obj1, obj2) => {
    const objects = [obj1, obj2];
    const isObject = (obj) => obj && typeof obj === 'object';
    return objects.reduce((prev, curr) => {
        Object.keys(curr).forEach(key => {
            const pVal = prev[key];
            const oVal = curr[key];
            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal);
            }
            else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = (0, exports.mergeDeep)(pVal, oVal);
            }
            else {
                prev[key] = oVal;
            }
        });
        return prev;
    }, {});
};
exports.mergeDeep = mergeDeep;
